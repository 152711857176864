/* purgecss start ignore */
body {
  margin: 0;
  padding: 0;
  font-family: sans-serif;
}

input:not([type='radio']):not([type='checkbox']) {
  -webkit-appearance: none;
}

@tailwind base;
@tailwind components;

body {
  @apply bg-cool-gray-200 font-sans;
}

.block-scroll {
  height: 100%;
  overflow: hidden;
}

.btn {
  @apply py-4 px-6 bg-cool-gray-400 rounded-lg text-white m-2 cursor-pointer transition ease-in-out duration-150;
  touch-action: manipulation;
}
.btn:focus {
  @apply outline-none shadow-outline;
}
.can-hover .btn:hover {
  @apply bg-cool-gray-500;
}
.can-hover .btn:active,
.can-hover .btn.btn-subtle:active,
.can-hover .btn.btn-blue:active,
.can-hover .btn.btn-magic:active {
  @apply shadow-outline;
}

.btn.btn-disabled {
  @apply opacity-25 select-none cursor-not-allowed;
}
.btn.btn-disabled.btn-magic {
  @apply opacity-50;
}
.can-hover .btn.btn-disabled:active {
  @apply outline-none;
}
.can-hover .btn.btn-disabled:hover {
  @apply bg-cool-gray-400;
}

.btn-blue,
.can-hover .btn-blue.btn-disabled:hover {
  @apply bg-blue-500 border-2 border-blue-500 border-solid;
}
.can-hover .btn-blue:hover {
  @apply bg-blue-600 border-2 border-blue-600 border-solid;
}

.btn-subtle,
.can-hover .btn-subtle.btn-disabled:hover {
  @apply bg-transparent border-cool-gray-300 border-solid border-2 text-cool-gray-600;
}
.can-hover .btn-subtle:hover {
  @apply bg-cool-gray-100 shadow;
}

.btn-blue.btn-subtle {
  @apply bg-transparent border-blue-400 text-blue-500;
}
.can-hover .btn-blue.btn-subtle:hover {
  @apply bg-cool-gray-100;
}

.btn-magic {
  @apply text-white border-none transition-all duration-100 ease-in-out;
  background-image: linear-gradient(135deg, #58b1ff, #2b6fd4);
}
.can-hover .btn-magic:hover:not(.btn-disabled) {
  @apply shadow;
  background-image: linear-gradient(135deg, #5fa5e2, #106ffb);
}

.btn-text {
  @apply bg-transparent py-2 px-4 self-center font-medium text-gray-800;
}
.can-hover .btn-text:hover {
  @apply text-gray-600 bg-transparent;
}

.select-btn {
  @apply p-4 m-2 border-cool-gray-300 border-solid border-2 rounded-lg cursor-pointer text-center flex items-center transition ease-in-out duration-150;
}

.can-hover .select-btn:not(.accented):hover {
  @apply shadow bg-cool-gray-100;
}
.can-hover .select-btn:focus,
.can-hover .select-btn:not(.accented):focus,
.can-hover .select-btn:active,
.can-hover .select-btn:not(.accented):active {
  @apply shadow-outline outline-none;
}

.textbox {
  @apply p-2 px-4 border-cool-gray-300 border-solid border-2 rounded-lg w-full;
  transition: 0.2s all ease;
}
.textbox:focus,
.textbox:focus-within {
  @apply outline-none shadow-outline;
}

.Select-placeholder {
  @apply text-cool-gray-400 !important;
}

.Select-placeholder,
.Select-input,
.Select-value {
  @apply px-4 !important;
}

.error-message {
  @apply text-red-700 mt-2 text-sm;
  animation-name: fadein;
  animation-duration: 0.4s;
}

.gm-style-cc {
  display: none;
}

.Select-control {
  @apply border-cool-gray-300 border-solid border-2 rounded-lg cursor-text !important;
  height: auto !important;
}
.Select-control:focus,
.Select-control:focus-within {
  @apply shadow-outline !important;
}
.Select-control .Select-input {
  height: 40px;
}
.Select-control .Select-placeholder,
.Select-control .Select-value {
  line-height: 40px !important;
}
.Select-control .Select-input > input {
  line-height: 24px;
}

body.tenant-tesla {
  @apply bg-black;
}

.tenant-tesla .btn,
.tenant-tesla .btn.btn-magic {
  @apply transition-colors duration-100 ease-in-out text-white uppercase font-medium !important;
  border: 1px solid #fff !important;
  background: transparent !important;
}

.tenant-tesla .btn:hover {
  background: radial-gradient(
    50% 50% at 50% 50%,
    rgba(102, 102, 102, 0.4) 0%,
    rgba(196, 196, 196, 0) 100%
  ) !important;
}

.tenant-tesla .btn.btn-blue,
.tenant-tesla .btn.btn-magic {
  background: linear-gradient(
    180deg,
    rgba(131, 131, 131, 0.6) 0%,
    rgba(80, 80, 80, 0) 100%
  ) !important;
}

.tenant-tesla .btn.btn-blue:hover,
.tenant-tesla .btn.btn-magic:hover {
  background: linear-gradient(
      180deg,
      rgba(131, 131, 131, 0.6) 0%,
      rgba(80, 80, 80, 0) 100%
    )
    radial-gradient(
      50% 50% at 50% 50%,
      rgba(102, 102, 102, 0.4) 0%,
      rgba(196, 196, 196, 0) 100%
    ) !important;
}

.tenant-tesla .Base {
  background: radial-gradient(
    50% 50% at 50% 50%,
    rgba(102, 102, 102, 0.4) 0%,
    rgba(196, 196, 196, 0) 100%
  );
}

.tenant-tesla .Nav {
  @apply bg-black;
}

.tenant-tesla .Nav .btn {
  border: none !important;
}

.text-true-black {
  @apply text-black;
}

.tenant-tesla .text-black,
.tenant-tesla .text-gray-700,
.tenant-tesla .text-cool-gray-600,
.tenant-tesla .text-cool-gray-700,
.tenant-tesla .text-cool-gray-800,
.tenant-tesla .text-cool-gray-900 {
  @apply text-gray-50;
}
.tenant-tesla .text-cool-gray-500 {
  @apply text-gray-200;
}
.tenant-tesla .bg-cool-gray-300 {
  @apply bg-gray-800;
}
.tenant-tesla .bg-cool-gray-600 {
  @apply bg-gray-200;
}
.tenant-tesla .bg-cool-gray-100,
.tenant-tesla .hover\:.bg-cool-gray-100:hover {
  @apply bg-black;
}

.tenant-tesla .select-btn {
  @apply text-gray-200 bg-transparent border border-gray-200 !important;
}

.tenant-tesla .Modal {
  @apply bg-black !important;
}

.tenant-tesla .bg-blue-100,
.tenant-tesla .hover\:bg-blue-100:hover,
.tenant-tesla .hover\:bg-cool-gray-100:hover {
  background-color: #5f5f5f;
}
.tenant-tesla .border-blue-300 {
  border-color: #5f5f5f;
}

.tenant-tesla .text-blue-600 {
  color: white;
}
.tenant-tesla .border-blue-600 {
  border-color: white;
}
.tenant-tesla .border-cool-gray-600,
.tenant-tesla .border-cool-gray-400 {
  border-color: white;
}

.tenant-tesla {
  font-family: 'Gotham A', 'Gotham B';
  font-style: normal;
  font-weight: 500;
}

.tenant-tesla .ManagePolicy {
  color: #fff;
}

.tenant-tesla .ManagePolicy .bg-white {
  background-color: #313131;
}

.tenant-tesla .ManagePolicy .bg-gray-50 {
  background-color: #4c4c4c;
}

.tenant-tesla .ManagePolicy .text-gray-900,
.tenant-tesla .ManagePolicy .text-gray-800,
.tenant-tesla .ManagePolicy .text-gray-700,
.tenant-tesla .ManagePolicy .text-gray-600,
.tenant-tesla .ManagePolicy .text-gray-500 {
  color: #fff;
}

.tenant-tesla .ManagePolicy .text-indigo-600 {
  color: #bebbf9;
}

.tenant-tesla .ManagePolicy .bg-blue-600 {
  background-color: #5e5e5e;
}

.tenant-tesla .ManagePolicy .bg-blue-400 {
  background-color: #5e5e5e;
}

.tenant-tesla .Card .text-gray-700 {
  @apply text-gray-700;
}

.tenant-tesla .Dropdown.text-gray-700 {
  @apply text-gray-700;
}

.tenant-tesla .bg-blue-300,
.tenant-tesla .bg-blue-500 {
  background-color: #1b1b1b;
}

.tenant-tesla .text-blue-500 {
  color: #fff;
}

.tenant-tesla .form-checkbox {
  @apply bg-gray-400;
}

.tenant-tesla .bg-green-100 {
  @apply bg-black border-white text-white border;
}

.tenant-tesla .text-green-600 {
  @apply text-white;
}

@screen sm {
  .tenant-tesla .ManagePolicy .sm\:bg-cool-gray-50 {
    background-color: #4e4e4e;
  }
}

/* USAA Tenant */

.tenant-usaa .text-cool-gray-600,
.tenant-usaa .text-cool-gray-700,
.tenant-usaa .textbox,
.tenant-usaa .Select-multi-value-wrapper,
.tenant-usaa .Select-value,
.tenant-usaa .Select-value-label,
.tenant-usaa .Select-input,
.tenant-usaa .Dropdown,
.tenant-usaa .form-checkbox {
  color: #003a63 !important;
}

.tenant-usaa .text-green-400 {
  @apply text-green-400 !important;
}
.tenant-usaa .text-green-600 {
  @apply text-green-600 !important;
}
.tenant-usaa .text-red-400 {
  @apply text-red-400 !important;
}
.tenant-usaa .text-red-600 {
  @apply text-red-600 !important;
}

/* USAA Tenant Btn */
.tenant-usaa .btn-blue {
  background-color: #003a63;
  border: none;
}
.tenant-usaa .btn-blue:hover {
  background-color: #00668a;
  border: none;
}

.tenant-usaa .btn-magic {
  background: linear-gradient(
    180deg,
    rgba(0, 102, 138, 1.5) 0%,
    rgba(18, 57, 91, 0.8) 100%
  ) !important;
}

/* Mercury Tenant Btn */
.tenant-mercury .btn-blue {
  background-color: #991b1e;
  border: none;
}
.tenant-mercury.can-hover .btn-blue:hover {
  background-color: #77080a;
  border: none;
}
.tenant-mercury .btn-magic {
  background: linear-gradient(90deg, #941a1b 0%, #77080a 100%) !important;
}

/* Sidekick */
.Sidekick {
  @apply bg-gray-200;
}
.Sidekick .btn-blue {
  background-color: #5850ec;
  border: none;
}
.Sidekick .btn-blue:hover {
  background-color: #6775f5;
  border: none;
}
.Sidekick .btn-magic {
  background: #5850ec;
}
.Sidekick .bg-cool-gray-600 {
  background: #5850ec;
}

/* purgecss end ignore */

@import './styles/animations.css';

@tailwind utilities;
